import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import DetrainAnimation_gif from '../images/Detrain-Cloud.gif'
import { Button } from "react-bootstrap"
import Card from 'react-bootstrap/Card'


const TimeSlicePage = () => {
  var aspect_ratio = 4 / 3
  return (
    <Layout pageTitle="INCUS TIME-SLICE">
      <h1 style={{ textAlign: 'center' }}>TIME&ndash;SLICE</h1>
      <h4><strong>T</strong>esting <strong>I</strong>NCUS <strong>M</strong>ethods <strong>E</strong>xperiment &mdash; <strong>S</strong>uborbital pre<strong>L</strong>aunch <strong>I</strong>nvestigations of <strong>C</strong>onvective <strong>E</strong>volution</h4>
      <Row>
        <Col md={6}>
          <p>
            The upcoming NASA Earth Venture Investigation of Convective Updrafts (INCUS) Mission will investigate convective mass flux (CMF) from space across tropical latitudes using Ka-band reflectivity differences obtained from three successive satellite observations. The time-differencing (delta-t) technique is based on the principle that the vertical air motion is the main contributor to observed changes in radar reflectivity (ΔZ/Δt) over short timescales (&lt;2 minutes). While this relationship is based on first principles (<a href="https://agupubs.onlinelibrary.wiley.com/doi/abs/10.1029/2022JD037701" target="_blank">Prasanth et al. 2023</a>), ground-based validation of INCUS principles, and ultimately space-based observations, require a suite of measurements that have not regularly been collected before. Understanding the critical measurements that are necessary to validate the INCUS products is necessary for ensuring a successful calibration/validation of the INCUS mission. Testing INCUS Methods Experiment &mdash; Suborbital preLaunch Investigations of Convective Evolution (TIME&ndash;SLICE) will build a proving ground to determine the types of suborbital data needed for INCUS delta-t validation. <em>As such, the overarching goal of TIME-SLICE is to assess, refine and develop the specific set of ground-based measurements, data, and parameters required to validate INCUS products. </em>Specifically, the result of the campaign will be an outline of the basic instruments, measurement strategies, and data required to determine CMF from the reflectivity delta-t technique.</p>
        </Col>
        <Col md={6}>
          <figure>
            <StaticImage
              alt="An overhead shot of the Semi-Arid Grasslands Research Center (SGRC/SAGR) taken from an aircraft with the instruments labeled"
              src="../images/time-slice/sgrc_site.png"
              layout="constrained"
              placeholder="blurred"
            />
            <figcaption>An overhead shot of the Semi-Arid Grasslands Research Center (SGRC/SAGR), the location of TIME&ndash;SLICE Colorado taken from an aircraft with the instruments labeled. Photo Credit: Brenda Dolan</figcaption>
          </figure>
        </Col>
      </Row>
      <h2>TIME&ndash;SLICE-CO Instruments</h2>
      <Row xs={1} md={4} className='g-4'>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A map showing the CHIVO and CHILL radars"
              src="../images/time-slice/time-slice-co-radars.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}

            />
          </div>
          <Card.Title>Scanning Radars</Card.Title>
          <Card.Body><a href="https://radar.weather.gov/?settings=v1_eyJhZ2VuZGEiOnsiaWQiOiJsb2NhbCIsImNlbnRlciI6Wy0xMDQuNTQ2LDM5Ljc4N10sImxvY2F0aW9uIjpudWxsLCJ6b29tIjo3LCJmaWx0ZXIiOiJXU1ItODhEIiwibGF5ZXIiOiJzcl9icmVmIiwic3RhdGlvbiI6IktGVEcifSwiYW5pbWF0aW5nIjpmYWxzZSwiYmFzZSI6InN0YW5kYXJkIiwiYXJ0Y2MiOmZhbHNlLCJjb3VudHkiOmZhbHNlLCJjd2EiOmZhbHNlLCJyZmMiOmZhbHNlLCJzdGF0ZSI6ZmFsc2UsIm1lbnUiOnRydWUsInNob3J0RnVzZWRPbmx5Ijp0cnVlLCJvcGFjaXR5Ijp7ImFsZXJ0cyI6MC44LCJsb2NhbCI6MC42LCJsb2NhbFN0YXRpb25zIjowLjgsIm5hdGlvbmFsIjowLjZ9fQ%3D%3D" target="_blank"><Button>KFTG</Button></a><a href="https://radar.weather.gov/?settings=v1_eyJhZ2VuZGEiOnsiaWQiOiJsb2NhbCIsImNlbnRlciI6Wy0xMDQuODA2LDQxLjE1Ml0sImxvY2F0aW9uIjpudWxsLCJ6b29tIjo3LCJmaWx0ZXIiOiJXU1ItODhEIiwibGF5ZXIiOiJzcl9icmVmIiwic3RhdGlvbiI6IktDWVMifSwiYW5pbWF0aW5nIjpmYWxzZSwiYmFzZSI6InN0YW5kYXJkIiwiYXJ0Y2MiOmZhbHNlLCJjb3VudHkiOmZhbHNlLCJjd2EiOmZhbHNlLCJyZmMiOmZhbHNlLCJzdGF0ZSI6ZmFsc2UsIm1lbnUiOnRydWUsInNob3J0RnVzZWRPbmx5Ijp0cnVlLCJvcGFjaXR5Ijp7ImFsZXJ0cyI6MC44LCJsb2NhbCI6MC42LCJsb2NhbFN0YXRpb25zIjowLjgsIm5hdGlvbmFsIjowLjZ9fQ%3D%3D" target="_blank"><Button>KCYS</Button></a></Card.Body>

        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of a white radome that contains the CHIVO radar."
              src="../images/time-slice/chivo-radars.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>CHIVO Radar &amp; MAAS </Card.Title>
          <Card.Body><a href="https://doppler.somas.stonybrook.edu/maas/ft_collins/" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of a two large drums with white vinyl wrap over them that contain the NOAA snow level radar."
              src="../images/time-slice/snow_level_radar.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>NOAA Snow Level Radar</Card.Title>
          <Card.Body><a href="https://psl.noaa.gov/data/obs/datadisplay/ViewDataType.php?DataTypeID=77&SiteID=sgc&DataSourceID=%25" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of a laser-based disdrometer in a field."
              src="../images/time-slice/disdrometer_higherres.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>NOAA Parsivel<sup>2</sup></Card.Title>
          <Card.Body><a href="https://psl.noaa.gov/data/obs/datadisplay/ViewDataType.php?DataTypeID=77&SiteID=sgc&DataSourceID=%25" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A plot of example CO LMA data."
              src="../images/time-slice/colma_example.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>CO LMA</Card.Title>
          <Card.Body><a href="http://lightning.nmt.edu/colma/current/" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A plot of example CO LMA data."
              src="../images/time-slice/tempest_1_co.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Tempest 1</Card.Title>
          <Card.Body><a href="https://tempestwx.com/station/140305/" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>

        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A plot of example CO LMA data."
              src="../images/time-slice/tempest_2_co.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Tempest 2</Card.Title>
          <Card.Body><a href="https://tempestwx.com/station/140303/" target="_blank"><Button>Quicklooks</Button></a></Card.Body>
        </Card>

        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of a student launching a sounding."
              src="../images/time-slice/soundings_co.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={aspect_ratio}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Soundings</Card.Title>
          <Card.Body><a href="https://vandenheever.atmos.colostate.edu/vdhpage/TIME-SLICE/TIMESLICE_latest.png" target="_blank"><Button>Quicklooks</Button></a></Card.Body>

        </Card>

      </Row>

    </Layout >

  )
}

export default TimeSlicePage

